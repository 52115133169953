import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ClubForm } from './club-edit.form';
import { urlValidator } from './url.validator';
import { phoneNumberValidator } from '../../../shared/validators/phone-number.validator';
import { validateRegion, validateZip } from '../../../core/helpers/validators';

@Injectable({ providedIn: 'root' })
export class ClubEditService {
  #fb = inject(NonNullableFormBuilder);

  public readonly clubForm: FormGroup<ClubForm> = this.#fb.group<ClubForm>({
    id: this.#fb.control(-1),
    location: this.#fb.group({
      id: this.#fb.control(-1),
      name: this.#fb.control('', Validators.required),
      street: this.#fb.control('', Validators.required),
      place: this.#fb.control({ id: 0, plz: '', province: '' }, [validateZip]),
      region: this.#fb.control({ id: 0, region: '' }, [validateRegion]),
      description: this.#fb.control('', Validators.required),
    }),
    contact: this.#fb.group({
      email: this.#fb.control('', [
        Validators.email,
        Validators.required,
        Validators.maxLength(255),
      ]),
      phoneNumber: this.#fb.control('', [
        Validators.required,
        Validators.maxLength(255),
        phoneNumberValidator(),
      ]),
    }),
    socials: this.#fb.group({
      instagramUrl: this.#fb.control('', [
        urlValidator(),
        Validators.maxLength(255),
      ]),
      facebookUrl: this.#fb.control('', [
        urlValidator(),
        Validators.maxLength(255),
      ]),
      twitterUrl: this.#fb.control('', [
        urlValidator(),
        Validators.maxLength(255),
      ]),
      websiteUrl: this.#fb.control('', [
        urlValidator(),
        Validators.maxLength(255),
      ]),
    }),
    logo: this.#fb.group({
      logoUrl: this.#fb.control('', Validators.required),
      logoOriginalUrl: this.#fb.control(''),
    }),
    clubId: this.#fb.control(null),
  });

  public validateAllFormFields(): void {
    this.clubForm.controls.location.markAllAsTouched();
    this.clubForm.controls.contact.markAllAsTouched();
    this.clubForm.controls.socials.markAllAsTouched();
    this.clubForm.controls.logo.markAllAsTouched();
  }
}
